import { useState } from "react";
import FormControl from "@mui/material/FormControl";
import Label from "../Label/Label";
import { StyledContainer } from "./styles";

interface ListBoxProps {
  id: string;
  title: string;
  options: any[];
  callback: (id: string, value: string) => void;
}

const ListBox = ({ id, title, options, callback }: ListBoxProps) => {
  const [value, setValue] = useState("");

  const selectChange = (event: any) => {
    setValue(event.target.value as string);
    callback(id, event.target.value);
  };
  return (
    <StyledContainer>
      <FormControl fullWidth>
        <Label id="select-label">{title}:</Label>
        <select id="select" value={value} onChange={selectChange} size={5}>
          {options.map((option: any) => (
            <option value={option.value}>{option.name}</option>
          ))}
        </select>
      </FormControl>
    </StyledContainer>
  );
};

export default ListBox;
