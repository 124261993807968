import GlobalStyles from "@mui/material/GlobalStyles";
import Theme from "../../base/theme";

const styles = {
  ".pintura-editor, pintura-editor": {
    "--color-primary": Theme.palette.primary.main,
    "--color-primary-text": Theme.palette.primary.contrastText,
    "--color-primary-dark": Theme.palette.primary.dark,
    "--color-secondary": Theme.palette.secondary.main,
    "--color-secondary-dark": Theme.palette.secondary.dark,
    "--font-family": Theme.typography.fontFamily,
  },
};

const EditorStyles = () => {
  return <GlobalStyles styles={styles} />;
};

export default EditorStyles;
