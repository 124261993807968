import React, { useState } from "react";
import { Rnd } from "react-rnd";

export type props = {
  children: React.ReactNode;
  initialPosition: {
    x: number;
    y: number;
  };
  initialSize: {
    width: number;
    height: number;
  };
  editable?: boolean;
};

const Element = ({
  children,
  initialPosition,
  initialSize,
  editable,
}: props) => {
  const [position, setPosition] = useState<any>(initialPosition);
  const [size, setSize] = useState<any>(initialSize);

  if (!editable) {
    return (
      <div
        style={{
          height: size.height,
          left: position.x,
          position: "absolute",
          top: position.y,
          width: size.width,
        }}
      >
        {children}
      </div>
    );
  }

  return (
    <Rnd
      size={{
        width: size.width,
        height: size.height,
      }}
      position={{
        x: position.x,
        y: position.y,
      }}
      onDragStop={(e, d) => {
        setPosition({
          x: d.x,
          y: d.y,
        });
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        setSize({
          width: ref.style.width,
          height: ref.style.height,
        });
      }}
    >
      {children}
    </Rnd>
  );
};

export default Element;
