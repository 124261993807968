import React from "react";
import { StyledFormGroup } from "./styles";
export type props = {
  children: React.ReactNode;
  style?: any;
};
const FormGroup = ({ children, style }: props) => {
  return <StyledFormGroup style={style}>{children}</StyledFormGroup>;
};

export default FormGroup;
