import { useState, useEffect, useCallback, useRef, createContext } from "react";
import axios from "axios";
import { cloneDeep } from "lodash";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";

import ProductPreview from "../components/ProductPreview/ProductPreview";
import CustomizationPanel from "../components/CustomizationPanel/CustomizationPanel";
import ErrorMessage from "../components/ErrorMessage/ErrorMessage";
import LoadScreen from "../components/loader/LoadScreen";
import GraphicEditor from "../components/GraphicEditor/GraphicEditor";

import ProductDataService from "../services/ProductDataService";

import PersonalizationData from "../models/PersonalizationData";
import UploadedImage from "../models/UploadedImage";

import EditorStyles from "../components/Editor/EditorStyles";
import "../styles.css";
import Button from "../components/Button/Button";
import useGetInitData from "../hooks/useGetInitData";

const emptyArray: any[] = [];

type errorContextProp = {
  errorState: {
    show: boolean;
    message: string;
  };
  setErrorState: any;
};

export const ErrorContext = createContext<errorContextProp | null>(null);

export function Editor(props: any) {
  const { data } = props;

  const [personalizationData, setPersonalizationData] = useState({
    customerId: "",
    customizationData: emptyArray,
    customizationImages: emptyArray,
    documentId: "",
    isPreview: false,
    marketplaceId: "",
    orderId: "",
    outputFormat: "",
    sku: "",
    documentName: "",
    productId: "",
  });
  const [productComponentList, setProductComponentList] = useState(emptyArray);
  const [personalizationComponentList, setPersonalizationComponentList] =
    useState(emptyArray);
  const [imageUrl, setImageUrl] = useState("");
  const [callbackData, setCallbackData] = useState({ id: "", value: "" });
  const [callbackImage, setCallbackImage] = useState({
    id: "",
    mime: "",
    file: "",
    title: "",
  });
  const uploadedImages = useRef<UploadedImage[]>([]);
  const [errorState, setErrorState] = useState({
    show: false,
    message: "",
  });
  const [finalizeData, setFinalizeData] = useState({
    proofMessage: "",
    approvalMessage: "",
  });

  const [loading, setLoading] = useState(true);
  const [imageLoading, setimageLoading] = useState(false);
  const [templateData, setTemplateData] = useState({
    width: 0,
    height: 0,
    background: "",
    colors: [],
    fonts: [],
  });
  const [mode, setMode] = useState<"form" | "graphic" | "hybrid">("form");
  const [PDF, setPDF] = useState({ url: "", customizationID: "" });

  useEffect(() => {
    if (data) {
      if (data.dataMode === "local") {
        let templetateData = {};
        if (data.editMode === "hybrid" || data.editMode === "graphic") {
          ProductDataService.getTemplateData(data).then((res) => {
            const values =
              ProductDataService.createPersonalizationDataAndComponents({
                ...data,
                ...{ ...(typeof res === "object" ? res : {}) },
              });
            if (values) {
              setPersonalizationData(values.personalizationData);
              setImageUrl(values.productImage);
              setProductComponentList(values.productComponentList);
              setPersonalizationComponentList(
                values.personalizationComponentList
              );
              setFinalizeData(values.finalizeData);
              setLoading(false);
              setTemplateData(values.templateData);
            }
          });
        } else {
          const values =
            ProductDataService.createPersonalizationDataAndComponents({
              ...data,
              ...templetateData,
            });
          if (values) {
            setPersonalizationData(values.personalizationData);
            setImageUrl(values.productImage);
            setProductComponentList(values.productComponentList);
            setPersonalizationComponentList(
              values.personalizationComponentList
            );
            setFinalizeData(values.finalizeData);
            setLoading(false);
            setTemplateData(values.templateData);
          }
        }
      } else {
        ProductDataService.initializeData(data)
          .then((res: any) => {
            setPersonalizationData(res.personalizationData);
            setImageUrl(res.productImage);
            setProductComponentList(res.productComponentList);
            setPersonalizationComponentList(res.personalizationComponentList);
            setFinalizeData(res.finalizeData);
            setLoading(false);
            setTemplateData(res.templateData);
          })
          .catch((error: any) => {
            setErrorState({
              show: true,
              message: "An error ocurred.",
            });
          });
      }
      if (data.editMode) {
        setMode(data.editMode);
      }
    }
  }, [data]);

  // TODO: Will need to be updated when case arises
  const handleCheckboxCallback = (
    id: string,
    optionId: string,
    value: boolean
  ) => {};

  const handleCallback = useCallback((id: string, value: string) => {
    setCallbackData({ id, value });
  }, []);

  const handleUploadImageCallback = useCallback(
    (id: string, mime: string, file: string, title: string) => {
      if (uploadedImages.current.length > 0) {
        const imageIndex = uploadedImages.current.findIndex(
          (el) => el.file === file
        );
        if (imageIndex !== -1) {
          let mime = "text/plain";
          let fileName = uploadedImages.current[imageIndex].name;
          if (fileName === "") {
            fileName = uploadedImages.current[imageIndex].file;
            mime = uploadedImages.current[imageIndex].mime;
          }
          setCallbackImage({ id, mime, file: fileName, title: title });
        } else {
          const index = uploadedImages.current.findIndex((el) => el.id === id);

          if (index !== -1) {
            uploadedImages.current[index] = { id, mime, file, name: "" };
          } else {
            uploadedImages.current.push({ id, mime, file, name: "" });
          }
          setCallbackImage({ id, mime, file, title });
        }
      } else {
        uploadedImages.current.push({ id, mime, file, name: "" });
        setCallbackImage({ id, mime, file, title });
      }
    },
    []
  );

  useEffect(() => {
    if (callbackData.id !== "" && callbackData.value !== "") {
      const newData = Object.assign({}, personalizationData);
      newData.customizationData = newData.customizationData.map((el) =>
        el.pcodeName === callbackData.id
          ? { ...el, pcodeValue: callbackData.value }
          : el
      );
      setPersonalizationData(newData);

      const updatedItems = productComponentList.map((item) => {
        if (item.id === callbackData.id) {
          return {
            ...item,
            value: {
              ...item.options.filter(
                (option: any) => option.value === callbackData.value
              )[0],
            },
          };
        }
        return item;
      });

      setProductComponentList(updatedItems);

      setCallbackData({ id: "", value: "" });

      if (mode === "hybrid") {
        const { id, value } = callbackData;
        const data = [...personalizationComponentList];
        const index = personalizationComponentList.findIndex(
          (component) => component.id === id
        );
        data[index] = { ...data[index], defaultText: value };

        setPersonalizationComponentList(data);
      }
    }
  }, [callbackData, personalizationData]);

  useEffect(() => {
    if (callbackImage?.id !== "" && callbackImage?.file !== "") {
      const newData = Object.assign({}, personalizationData);
      newData.customizationImages = newData.customizationImages.map((el) =>
        el.pcodeName === callbackImage.id
          ? {
              ...el,
              pcodeValue: {
                mime: callbackImage.mime,
                data: callbackImage.file,
              },
            }
          : el
      );
      setCallbackImage({ id: "", mime: "", file: "", title: "" });
      setPersonalizationData(newData);
    }
  }, [callbackImage, personalizationData]);

  const getImage = () => {
    if (personalizationData !== null && personalizationData !== undefined) {
      const isEmpty = personalizationData?.customizationData.find(
        (el) => el.pcodeValue !== ""
      );
      const imagesToPersonalize =
        personalizationData?.customizationImages.filter(
          (el) => el.pcodeValue.data !== null
        );
      if (
        (isEmpty !== null && isEmpty !== undefined) ||
        imagesToPersonalize.length > 0
      ) {
        const personalizationDataApi: PersonalizationData =
          cloneDeep(personalizationData);
        imagesToPersonalize?.forEach((el) => {
          const uploadedImage = uploadedImages.current.find(
            (img) => img.file === el.pcodeValue.data
          );
          if (
            uploadedImage !== null &&
            uploadedImage !== undefined &&
            uploadedImage.name !== ""
          ) {
            el.pcodeValue.mime = "text/plain";
            el.pcodeValue.data = uploadedImage.name;
          }
          personalizationDataApi?.customizationData.push(el);
        });

        personalizationDataApi.isPreview = false;
        personalizationDataApi.outputFormat = "pdf";
        setimageLoading(true);
        const url =
          "https://jcdevapi.cierant.com/customize/" + personalizationData?.sku;
        axios
          .post(url, personalizationDataApi, {
            headers: {
              Authorization:
                "Basic MTAxOjJDNTBBNDE0LTA5NjUtNDdBMi04RTY3LTQ1Q0FFMUNERDE3OQ==" ||
                "",
            },
          })
          .then((res) => {
            setimageLoading(false);
            setPDF({
              url: res.data.customizeURL,
              customizationID: res.data.customizationID,
            });
            window.open(res.data.customizeURL, "_blank");
          })
          .catch((err) => {
            console.log("err: ", err);
          });
      }
    }
  };

  useEffect(() => {
    if (personalizationData !== null && personalizationData !== undefined) {
      const isEmpty = personalizationData?.customizationData.find(
        (el) => el.pcodeValue !== ""
      );
      const imagesToPersonalize =
        personalizationData?.customizationImages.filter(
          (el) => el.pcodeValue.data !== null
        );
      if (
        (isEmpty !== null && isEmpty !== undefined) ||
        imagesToPersonalize.length > 0
      ) {
        const personalizationDataApi: PersonalizationData =
          cloneDeep(personalizationData);
        imagesToPersonalize?.forEach((el) => {
          const uploadedImage = uploadedImages.current.find(
            (img) => img.file === el.pcodeValue.data
          );
          if (
            uploadedImage !== null &&
            uploadedImage !== undefined &&
            uploadedImage.name !== ""
          ) {
            el.pcodeValue.mime = "text/plain";
            el.pcodeValue.data = uploadedImage.name;
          }
          personalizationDataApi?.customizationData.push(el);
        });

        // if graphic mode only update background not text
        if (mode === "graphic") {
          const productComponentIds = productComponentList.map(
            (item) => item.id
          );
          const backgroundValues =
            personalizationDataApi.customizationData.filter((data) => {
              if (productComponentIds.includes(data.pcodeName)) {
                return data;
              }
              return null;
            });

          personalizationDataApi.customizationData = backgroundValues;
        }

        // if graphic mode only update background not text
        if (mode === "hybrid") {
          const productComponentIds = productComponentList.map(
            (item) => item.id
          );
          const backgroundValues =
            personalizationDataApi.customizationData.filter((data) => {
              if (productComponentIds.includes(data.pcodeName)) {
                return data;
              }
              if (data.pcodeType === "image") {
                return data;
              }
              return null;
            });

          personalizationDataApi.customizationData = backgroundValues;
        }

        if (mode !== "graphic" && mode !== "hybrid") {
          setimageLoading(true);
        }
        const url =
          "https://jcdevapi.cierant.com/customize/" + personalizationData?.sku;
        axios
          .post(url, personalizationDataApi, {
            headers: {
              Authorization:
                "Basic MTAxOjJDNTBBNDE0LTA5NjUtNDdBMi04RTY3LTQ1Q0FFMUNERDE3OQ==" ||
                "",
            },
          })
          .then((res) => {
            if (
              res.data.customizeURL !== null &&
              res.data.customizeURL !== undefined &&
              res.data.customizeURL !== ""
            ) {
              setimageLoading(false);
              setImageUrl(res.data.customizeURL);
              setTemplateData({
                ...templateData,
                background: res.data.customizeURL,
              });
            }

            res.data.customImages.forEach((el: any) => {
              const imageToUpdate = imagesToPersonalize.find(
                (image: any) => image.pcodeName === el.pCode
              );
              if (imageToUpdate !== null && imageToUpdate !== undefined) {
                const imageIndex = uploadedImages.current.findIndex(
                  (img) => img.file === imageToUpdate.pcodeValue.data
                );
                if (imageIndex !== -1) {
                  uploadedImages.current[imageIndex].name = el.fileName;
                }
              }
            });
          })
          .catch((err) => {
            console.log("err: ", err);
          });
      }
    }
  }, [personalizationData, mode, productComponentList]);

  const handleProf = () => {
    getImage();
  };
  const handleSave = () => {
    const { orderId, documentId, sku, documentName, productId } =
      personalizationData;

    let finalData = {
      orderId,
      sku,
      productId,
      documentId,
      documentName,
      customizeId: PDF.customizationID,
      customizeURL: PDF.url,
      isApproved: true,
      productOptions: productComponentList.map((item) => ({
        optionId: item?.value?.optionItemId,
        name: item.name,
        value: item?.value?.value,
        cost: item?.value?.cost,
        isUnit: item?.value?.isUnit,
      })),
    };

    const enteredData = {
      ...personalizationData,
      customizationImages: personalizationData.customizationImages.map(
        (item, key) => {
          return { ...item, name: uploadedImages.current[key].name };
        }
      ),
    };

    window?.top?.postMessage({ data: enteredData, initialData: data }, "*");
  };

  if (!data) return null;

  return (
    <ErrorContext.Provider value={{ errorState, setErrorState }}>
      <CssBaseline />
      <EditorStyles />
      {loading ? (
        <LoadScreen />
      ) : (
        <Grid container spacing={2} className="wrapper">
          <Grid xs={12} style={{ display: "none" }}>
            <Button onClick={() => setMode("form")}>Mode Form</Button>
            <Button onClick={() => setMode("graphic")}>Mode Graphic</Button>
            <Button onClick={() => setMode("hybrid")}>Mode Hybrid</Button>
          </Grid>
          {mode === "graphic" || mode === "hybrid" ? (
            <GraphicEditor
              templateData={templateData}
              personalizationComponentList={personalizationComponentList}
              handleCallback={handleCallback}
              handleUploadImageCallback={handleUploadImageCallback}
              loading={imageLoading}
              mode={mode}
            />
          ) : (
            <ProductPreview imageUrl={imageUrl} loading={imageLoading} />
          )}
          <CustomizationPanel
            personalizationComponentList={personalizationComponentList}
            productsComponentList={productComponentList}
            handleCallback={handleCallback}
            handleCheckboxCallback={handleCheckboxCallback}
            handleUploadImageCallback={handleUploadImageCallback}
            handleProf={handleProf}
            handleSave={handleSave}
            finalizeData={finalizeData}
            mode={mode}
            showOptions={data.showOptions}
            marketplaceId={personalizationData.marketplaceId}
            documentId={personalizationData.documentId}
          />
        </Grid>
      )}
      <ErrorMessage message={errorState.message} />
    </ErrorContext.Provider>
  );
}

const EditorWrapper = () => {
  const [data, setData] = useState(null);
  useGetInitData(setData);

  if (!data) return null;
  return <Editor data={data} />;
};

Editor.defaultProps = {
  data: {},
};

export default EditorWrapper;
