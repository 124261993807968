import { useContext } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { ErrorContext } from "../../pages/Editor";

export type props = {
  message: string;
};

const ErrorMessage = ({ message }: props) => {
  const context = useContext(ErrorContext);
  return (
    <Snackbar
      open={context?.errorState.show}
      onClose={() =>
        context?.setErrorState({
          show: false,
          message: "",
        })
      }
      autoHideDuration={5000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert severity="error">{message}</Alert>
    </Snackbar>
  );
};

export default ErrorMessage;
