const getFileToBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = (error: any) => reject(error);
    });
};

const ImageService = {
    getFileToBase64
}

export default ImageService;
