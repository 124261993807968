import { StyledGrid } from "./styles";
import LoadScreen from "../loader/LoadScreen";

export type props = {
  imageUrl: string;
  loading: boolean;
};

const ProductPreview = ({ imageUrl, loading }: props) => {
  return (
    <StyledGrid item xs={12} md={6}>
      {loading && <LoadScreen opacity />}
      <img
        className={"product-preview-image"}
        src={imageUrl}
        alt="Product Preview"
      />
    </StyledGrid>
  );
};

export default ProductPreview;
