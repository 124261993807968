import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  StyledTitle,
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
} from "./styles";

export type props = {
  summary?: string | React.ReactNode;
  children: React.ReactNode;
  expanded?: boolean;
  summaryClick?: () => void;
};

const Accordion = ({ summary, children, expanded, summaryClick }: props) => {
  return (
    <StyledAccordion expanded={expanded} onChange={summaryClick}>
      <StyledAccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        expandIcon={<ExpandMoreIcon />}
      >
        <StyledTitle>{summary}</StyledTitle>
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default Accordion;
