import { styled } from "@mui/material/styles";
import FormLabel, { FormLabelProps } from "@mui/material/FormLabel";

export const StyledLabel = styled((props: FormLabelProps) => {
  return (
    <FormLabel
      {...props}
      className={`${props.className} ${props.color === "error" ? "error" : ""}`}
    />
  );
})(({ theme }) => ({
  color: theme.palette.grey[700],
  display: "block",
  fontSize: theme.typography.body1.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: theme.typography.body1.lineHeight,
  margin: `0 0 ${theme.spacing(2)}`,
  "&.error": {
    color: theme.palette.error.main,
  },
}));
