import React from "react";
import { StyledRadioGroup } from "./styles";
export type props = {
  children: React.ReactNode;
  name?: string | undefined;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => void;
  row?: boolean;
  defaultValue?: string;
  value?: string;
};
const RadioGroup = ({ children, name, onChange, row }: props) => {
  return (
    <StyledRadioGroup name={name} onChange={onChange} row={row}>
      {children}
    </StyledRadioGroup>
  );
};

export default RadioGroup;
