import { createTheme } from "@mui/material/styles";
import colors from "./colorsTheme";
import typography from "./fontsTheme";
import variables from "../variables";

const { shape, spacing, breakpoints } = variables;

const Theme = createTheme({
  breakpoints: {
    values: breakpoints,
  },
  palette: colors,
  shape,
  spacing,
  typography,
});

export default Theme;
