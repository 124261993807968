import React from "react";
import { StyledLabel } from "./styles";
export type props = {
  children: React.ReactNode;
  id: string;
  color?: "primary" | "secondary" | "error" | "info" | "success" | "warning";
};

const Label = ({ children, id, color }: props) => {
  return (
    <StyledLabel id={id} color={color}>
      {children}
    </StyledLabel>
  );
};

export default Label;
