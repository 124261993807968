import { useRef } from "react";
import { PinturaEditorModal } from "react-pintura";
import { getEditorDefaults } from "pintura";
import { StyledContainer } from "./styles";
import "pintura/pintura.css";

export type editorProps = {
  image: string;
  onProcess: (e: any) => void;
  onClose: () => void;
};

const Editor = ({ image, onProcess, onClose }: editorProps) => {
  const componentRef = useRef(null);
  // get default properties
  const editorConfig = getEditorDefaults();

  return (
    <StyledContainer>
      <PinturaEditorModal
        {...editorConfig}
        src={image}
        imageCropAspectRatio={1}
        ref={componentRef}
        onProcess={onProcess}
        onClose={onClose}
      ></PinturaEditorModal>
    </StyledContainer>
  );
};

export default Editor;
