import Alert from "@mui/material/Alert";
export type props = {
  message: string;
};

const StaticError = ({ message }: props) => {
  return <Alert severity="error">{message}</Alert>;
};

export default StaticError;
