import { useState, useEffect, memo, useRef } from "react";
import { Textfit } from "react-textfit";
import { StyledTextArea } from "./styles";

interface TextAreaProps {
  id: string;
  title?: string;
  defaultText: string;
  callback: (id: string, value: string) => void;
  style: any;
  maxlength?: number;
}

const TextArea = ({
  callback,
  defaultText,
  id,
  maxlength,
  style,
}: TextAreaProps) => {
  const [value, setValue] = useState(defaultText);
  const [fontSize, setFontSize] = useState(style.fontSize);
  const elementRef = useRef<any>(null);

  const textChange = (event: any) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (value !== null && value !== undefined && value !== "") {
        callback(id, value);
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [value, id, callback]);

  const styles = {
    color: style.color,
    fontFamily: style.fontFamily,
    fontSize: style.fontSize,
    height: style.height,
    lineHeight: "normal",
    position: "absolute",
    top: "-1000px",
    width: "85%",
  };

  return (
    <>
      <div style={styles as React.CSSProperties}>
        <Textfit
          mode="single"
          ref={elementRef}
          max={style.fontSize}
          forceSingleModeWidth={false}
          onReady={() => {
            setFontSize(elementRef.current.state.fontSize);
          }}
          style={{ height: style.height }}
        >
          {value}
        </Textfit>
      </div>
      <StyledTextArea
        id="text-field"
        onChange={textChange}
        style={{
          ...style,
          fontSize: fontSize,
          lineHeight: `${fontSize - 10}px`,
        }}
        maxLength={maxlength}
        value={value}
      />
    </>
  );
};

export default memo(TextArea);
