"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
var axios_1 = require("axios");
var getInitialProductImage = function (data) {
    if (data !== null && data !== undefined) {
        return data.productImage;
    }
    return "";
};
var initializeData = function (data) {
    return new Promise(function (resolve, reject) {
        if (data !== null && data !== undefined) {
            if (data.marketplaceId !== "" &&
                (data.productId !== "" || data.sku !== "") &&
                data.marketplaceId !== undefined &&
                (data.productId !== undefined || data.sku !== undefined)) {
                var requestData = {
                    customizeId: data.customizeId,
                    marketplaceId: data.marketplaceId,
                    productId: data.productId,
                    sku: data.sku
                };
                return getInitializeData(requestData)
                    .then(function (initializeData) {
                    var result = createPersonalizationDataAndComponents(initializeData);
                    resolve(result);
                })["catch"](function (error) {
                    reject(error);
                });
            }
        }
    });
};
var getInitializeData = function (requestData) {
    return new Promise(function (resolve, reject) {
        var url = "https://jcdevapi.cierant.com/initialize";
        axios_1["default"]
            .post(url, requestData, {
            headers: {
                Authorization: "Basic MTAxOjJDNTBBNDE0LTA5NjUtNDdBMi04RTY3LTQ1Q0FFMUNERDE3OQ==" ||
                    ""
            }
        })
            .then(function (res) {
            resolve(JSON.parse(res.data.message)[0]);
        })["catch"](function (error) {
            console.log("error: ", error);
            reject(error);
        });
    });
};
var getTemplateData = function (requestData) {
    return new Promise(function (resolve, reject) {
        var url = "https://jcdevapi.cierant.com/template";
        axios_1["default"]
            .post(url, __assign(__assign({}, requestData), { sessionId: "F02B3B9F-5EC0-4CF8-82EB-4C616D01EFFD" }), {
            headers: {
                Authorization: "Basic MTAxOjJDNTBBNDE0LTA5NjUtNDdBMi04RTY3LTQ1Q0FFMUNERDE3OQ==" ||
                    ""
            }
        })
            .then(function (res) {
            resolve(JSON.parse(res.data.message)[0]);
        })["catch"](function (error) {
            console.log("error: ", error);
            reject(error);
        });
    });
};
var createPersonalizationDataAndComponents = function (data) {
    if (data !== null && data !== undefined) {
        var productImage = getInitialProductImage(data);
        var personalizationDataList_1 = [];
        var personalizationImageList_1 = [];
        var productComponentList_1 = [];
        var personalizationComponentList_1 = [];
        if (data.productOptions !== null &&
            data.productOptions !== undefined &&
            data.productOptions.length > 0) {
            data.productOptions.forEach(function (option) {
                var pcode = "";
                if (option.hasOwnProperty("pcode") && option.pcode !== "") {
                    pcode = option.pcode;
                    //Creating the data that will be sent to the api
                    var productData = {
                        description: option.description,
                        OptionId: option.OptionId,
                        pcodeName: pcode,
                        pcodeType: "text",
                        pcodeValue: option.selected ? option.selected : "",
                        isVAR: option.isVAR
                    };
                    personalizationDataList_1.push(productData);
                }
                //Creating the product component that will be displayed on the ui
                var type = option.controlType.toLowerCase();
                if (type === "radio" &&
                    option.optionItems[0].hasOwnProperty("imageFile") &&
                    option.optionItems[0].imageFile !== "") {
                    type = "radioImage";
                }
                var component = {
                    name: option.name,
                    id: pcode || option.name,
                    type: type,
                    title: option.description,
                    options: option.optionItems,
                    defaultValue: option.selected
                };
                productComponentList_1.push(component);
            });
        }
        if (data.personalizationData !== null &&
            data.personalizationData !== undefined &&
            data.personalizationData.length > 0) {
            data.personalizationData.forEach(function (option, key) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
                //Creating the data that will be sent to the api
                if (option.pCodeType.toLowerCase() === "image") {
                    var personalizeData = {
                        description: option.description,
                        pcodeName: option.pcode,
                        pcodeType: option.pCodeType,
                        pcodeValue: {
                            mime: null,
                            data: null
                        },
                        isVAR: option.isVAR,
                        defaultText: option.defaultText || ""
                    };
                    personalizationImageList_1.push(personalizeData);
                }
                else {
                    var personalizeData = void 0;
                    if (data.pZone === undefined) {
                        personalizeData = {
                            description: option.description,
                            pcodeName: option.pcode,
                            pcodeType: option.pCodeType.toLowerCase(),
                            isVAR: option.isVAR,
                            charLimit: option.charLimit,
                            pcodeValue: option.defaultText || "",
                            defaultText: option.defaultText || ""
                        };
                    }
                    else {
                        personalizeData = {
                            description: option.description,
                            pcodeName: option.pcode,
                            pcodeType: option.pCodeType.toLowerCase(),
                            pcodeValue: ((_a = data.pZone[key]) === null || _a === void 0 ? void 0 : _a.defaultText) || option.defaultText || "",
                            isVAR: option.isVAR,
                            defaultText: ((_b = data.pZone[key]) === null || _b === void 0 ? void 0 : _b.defaultText) || "",
                            charLimit: ((_c = data.pZone[key]) === null || _c === void 0 ? void 0 : _c.charLimit) || ""
                        };
                    }
                    personalizationDataList_1.push(personalizeData);
                }
                //Creating the personalization component that will be displayed on the ui
                var defaultText = option.defaultText === undefined || option.defaultText === null
                    ? ""
                    : option.defaultText;
                var component;
                if (option.pCodeType.toLowerCase() === "image") {
                    if (data.pZone === undefined) {
                        component = {
                            id: option.pcode,
                            type: option.pCodeType,
                            name: option.pcode,
                            title: option.description,
                            defaultText: option.defaultText || "",
                            height: option.imageHeight,
                            width: option.imageWidth
                        };
                    }
                    else {
                        component = {
                            id: option.pcode,
                            type: option.pCodeType,
                            name: option.pcode,
                            title: option.description,
                            defaultText: ((_d = data.pZone[key]) === null || _d === void 0 ? void 0 : _d.defaultText) || defaultText,
                            pxWidth: (_e = data.pZone[key]) === null || _e === void 0 ? void 0 : _e.width,
                            pxHeight: (_f = data.pZone[key]) === null || _f === void 0 ? void 0 : _f.height,
                            width: option.imageWidth,
                            height: option.imageHeight,
                            x: (_g = data.pZone[key]) === null || _g === void 0 ? void 0 : _g.xpos,
                            y: (_h = data.pZone[key]) === null || _h === void 0 ? void 0 : _h.ypos
                        };
                    }
                }
                else {
                    if (data.pZone === undefined) {
                        component = {
                            defaultText: option.defaultText || "",
                            id: option.pcode,
                            name: option.pcode,
                            title: option.description,
                            type: option.pCodeType,
                            maxlength: option.charLimit
                        };
                    }
                    else {
                        component = {
                            defaultText: ((_j = data.pZone[key]) === null || _j === void 0 ? void 0 : _j.defaultText) || defaultText,
                            fontColor: (_k = data.pZone[key]) === null || _k === void 0 ? void 0 : _k.fontColor,
                            fontFamily: (_l = data.pZone[key]) === null || _l === void 0 ? void 0 : _l.fontName,
                            fontSize: (_m = data.pZone[key]) === null || _m === void 0 ? void 0 : _m.fontSize,
                            height: (_o = data.pZone[key]) === null || _o === void 0 ? void 0 : _o.height,
                            id: option.pcode,
                            name: option.pcode,
                            textAlign: (_p = data.pZone[key]) === null || _p === void 0 ? void 0 : _p.textAlign,
                            title: option.description,
                            type: option.pCodeType,
                            width: (_q = data.pZone[key]) === null || _q === void 0 ? void 0 : _q.width,
                            x: (_r = data.pZone[key]) === null || _r === void 0 ? void 0 : _r.xpos,
                            y: (_s = data.pZone[key]) === null || _s === void 0 ? void 0 : _s.ypos,
                            maxlength: option.charLimit
                        };
                    }
                }
                personalizationComponentList_1.push(component);
            });
        }
        var personalizationData = {
            customerId: data.customerId,
            marketplaceId: data.marketplaceId,
            // TODO: This should not be hard coded
            orderId: "25099900",
            sku: data.sku,
            documentId: data.documentId,
            isPreview: true,
            outputFormat: "jpg",
            customizationData: personalizationDataList_1,
            documentName: data.documentName,
            customizationImages: personalizationImageList_1,
            productId: data.productId
        };
        var finalizeData = {
            proofMessage: data.proofMessage,
            approvalMessage: data.approvalMessage
        };
        var templateData = {
            width: data.width,
            height: data.height,
            background: data.background,
            fonts: data.fonts,
            colors: data.fontColors
        };
        return {
            productImage: productImage,
            personalizationData: personalizationData,
            productComponentList: productComponentList_1,
            personalizationComponentList: personalizationComponentList_1,
            templateData: templateData,
            finalizeData: finalizeData
        };
    }
};
var ProductDataService = {
    getInitialProductImage: getInitialProductImage,
    initializeData: initializeData,
    getInitializeData: getInitializeData,
    createPersonalizationDataAndComponents: createPersonalizationDataAndComponents,
    getTemplateData: getTemplateData
};
exports["default"] = ProductDataService;
