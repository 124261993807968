import { pxToRem } from "../utils";

export const fontBreakpoint = "md";

export const fontFamily = {
  primary: "Roboto",
  secondary: "Monserrat",
};
const global = 16;

export const typography = {
  global: global,
  fontFamily: fontFamily.primary,
  h1: {
    fontFamily: fontFamily.primary,
    xs: {
      fontSize: pxToRem(global, 84),
      lineHeight: pxToRem(global, 112),
    },
    [fontBreakpoint]: {
      fontSize: pxToRem(global, 200),
      lineHeight: pxToRem(global, 112),
    },
  },
  h2: {
    fontFamily: fontFamily.primary,
    xs: {
      fontSize: pxToRem(global, 60),
      lineHeight: pxToRem(global, 72),
    },
    [fontBreakpoint]: {
      fontSize: pxToRem(global, 60),
      lineHeight: pxToRem(global, 72),
    },
  },
  h3: {
    fontFamily: fontFamily.primary,
    xs: {
      fontSize: pxToRem(global, 48),
      lineHeight: pxToRem(global, 56),
    },
    [fontBreakpoint]: {
      fontSize: pxToRem(global, 48),
      lineHeight: pxToRem(global, 56),
    },
  },
  h4: {
    fontFamily: fontFamily.primary,
    xs: {
      fontSize: pxToRem(global, 34),
      lineHeight: pxToRem(global, 42),
    },
    [fontBreakpoint]: {
      fontSize: pxToRem(global, 34),
      lineHeight: pxToRem(global, 42),
    },
  },
  h5: {
    fontFamily: fontFamily.primary,
    xs: {
      fontSize: pxToRem(global, 24),
      lineHeight: pxToRem(global, 32),
    },
    [fontBreakpoint]: {
      fontSize: pxToRem(global, 24),
      lineHeight: pxToRem(global, 32),
    },
  },
  h6: {
    fontFamily: fontFamily.primary,
    xs: {
      fontSize: pxToRem(global, 20),
      lineHeight: pxToRem(global, 32),
    },
    [fontBreakpoint]: {
      fontSize: pxToRem(global, 20),
      lineHeight: pxToRem(global, 32),
    },
  },
  p: {
    fontFamily: fontFamily.primary,
    xs: {
      fontSize: pxToRem(global, 16),
      lineHeight: pxToRem(global, 24),
    },
    [fontBreakpoint]: {
      fontSize: pxToRem(global, 16),
      lineHeight: pxToRem(global, 24),
    },
  },
};
