import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { default as emotionStyled } from "@emotion/styled";

export const StyledInput = styled((props: TextFieldProps) => (
  <TextField {...props} />
))(({ theme, multiline }) => ({
  fontFamily: theme.typography.body1.fontFamily,
  fontSize: theme.typography.body1.fontSize,
  lineHeight: theme.typography.body1.lineHeight,
  "& input": {
    padding: multiline ? theme.spacing(1) : theme.spacing(1),
  },
}));

export const StyledCount = emotionStyled.p`
  margin-top: 5px;
`;
