import FormGroup, { FormGroupProps } from "@mui/material/FormGroup";
import RadioGroup, { RadioGroupProps } from "@mui/material/RadioGroup";
import { styled } from "@mui/material/styles";

export const StyledFormGroup = styled((props: FormGroupProps) => (
  <FormGroup {...props} />
))(({ theme }) => ({
  margin: `0 0 ${theme.spacing(3)} !important`,
}));

export const StyledRadioGroup = styled((props: RadioGroupProps) => (
  <RadioGroup {...props} />
))(({ theme }) => ({
  margin: `0 0 ${theme.spacing(3)} !important`,
}));
