import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export type props = {
  show: boolean;
  close: () => void;
  error?: boolean;
  message?: string;
};

const SaveMessage = ({ show, close, error, message }: props) => {
  return (
    <Snackbar
      open={show}
      onClose={() => close()}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      {error ? (
        <Alert severity="error">
          {message ? message : "An error occurred, please try again later"}
        </Alert>
      ) : (
        <Alert severity="success">
          {message ? message : "The tab information was saved succesfully"}
        </Alert>
      )}
    </Snackbar>
  );
};

export default SaveMessage;
