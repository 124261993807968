import React, { useEffect } from "react";

const useGetInitData = (handleButtonClick) => {
  const getData = (e) => {
    handleButtonClick(null);
    const { productid, sku } = e.target.dataset;
    const data = {
      productId: productid,
      sku,
      marketplaceId: "E754766B-7EB0-459D-AB6D-5DC6A9681D47",
      sessionId: "F02B3B9F-5EC0-4CF8-82EB-4C616D01EFFD",
    };
    setTimeout(() => {
      handleButtonClick(data);
    }, 500);
  };

  const getData2 = (e) => {
    handleButtonClick(null);
    setTimeout(() => {
      const data = window.product;
      handleButtonClick({ ...data });
    }, 500);
  };

  useEffect(() => {
    window.addEventListener(
      "message",
      (event) => {
        handleButtonClick({ ...event.data });
        if (event.origin !== "http://example.org:8080") return;

        // …
      },
      false
    );
    const buttons = document.querySelectorAll(".product");
    const buttons2 = document.querySelectorAll(".product2");

    buttons.forEach((button) => {
      button.addEventListener("click", getData);
    });

    buttons2.forEach((button) => {
      button.addEventListener("click", getData2);
    });

    return () => {
      buttons.forEach((button) => {
        button.removeEventListener("click", getData);
      });
      buttons2.forEach((button) => {
        button.removeEventListener("click", getData2);
      });
      window.removeEventListener("click", getData);
    };
  });
};

export default useGetInitData;
