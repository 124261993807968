import Styled from "@emotion/styled";
import Theme from "../../base/theme/Theme";
import Grid, { GridProps } from "@mui/material/Grid";
import { styled as muiStyled } from "@mui/material/styles";

export const StyledGrid = muiStyled((props: GridProps) => <Grid {...props} />)({
  display: "flex",
  justifyContent: "center",
  position: "relative",
});

export const Container = Styled.div`
  align-items: center;
  display:flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  .bar {
    position: relative;
  }

  .options-container{
    align-items: center;
    display:flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: ${Theme.spacing(3)}
  }
  .image-placeholder{
    width: 100%;
    height: 100%;
    background-color: ${Theme.palette.grey[300]}
  }

  .template-background{
    max-width: 100%;
  }
`;

export const TextArea = Styled.textarea`
  background-color: transparent;
  border: 1px solid black;
  height: 100%;
  resize: none;
  width: 100%;
`;
