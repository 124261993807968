import Loader from "./Loader";
import { StyledContainer } from "./styles";

export type props = {
  opacity?: boolean;
};
const LoadScreen = ({ opacity }: props) => {
  return (
    <StyledContainer className={opacity ? "opacity" : ""}>
      <Loader />
    </StyledContainer>
  );
};

export default LoadScreen;
