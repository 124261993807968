import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Editor from "./pages/Editor";
import Done from "./pages/Done";

import Theme from "./base/theme";

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Editor />} />
          <Route path="/done" element={<Done />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
