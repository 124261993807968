import { useState } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Label from "../Label/Label";
import { StyledContainer } from "./styles";

interface SelectInputProps {
  id: string;
  title: string;
  options: any[];
  callback: (id: string, value: string) => void;
}

const SelectInput = ({ id, title, options, callback }: SelectInputProps) => {
  const [value, setValue] = useState("");

  const selectChange = (event: any) => {
    setValue(event.target.value as string);
    callback(id, event.target.value);
  };
  return (
    <StyledContainer>
      <FormControl fullWidth>
        <Label id="select-label">{title}:</Label>
        <Select
          labelId="select-label"
          id="select"
          value={value}
          onChange={selectChange}
        >
          {options.map((option) => {
            return (
              <MenuItem key={option.optionItemId} value={option.value}>
                {option.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </StyledContainer>
  );
};

export default SelectInput;
