import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Label from "../Label/Label";
import FormGroup from "../FormGroup/FormGroup";

interface CheckboxInputProps {
  id: string;
  title?: string;
  options: any[];
  callback: (id: string, optionId: string, value: boolean) => void;
}

const CheckboxInput = ({
  id,
  title,
  options,
  callback,
}: CheckboxInputProps) => {
  const checkboxChange = (event: any) => {
    callback(id, event.target.labels[0].id, event.target.checked);
  };
  return (
    <FormGroup>
      {title && <Label id="checkbox-selection">{title}:</Label>}
      {options.map((option) => (
        <FormControlLabel
          key={option.optionItemId}
          id={option.optionItemId}
          control={<Checkbox />}
          label={option.name}
          onChange={checkboxChange}
        />
      ))}
    </FormGroup>
  );
};

export default CheckboxInput;
