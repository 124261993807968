import Grid, { GridProps } from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

export const StyledGrid = styled((props: GridProps) => <Grid {...props} />)({
  display: "flex",
  position: "relative",
  justifyContent: "center",
  alignItems: "baseline",
  "& .product-preview-image": {
    height: "fit-content",
    maxWidth: "80%",
    width: "fit-content",
  },
});
