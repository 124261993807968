import Styled from "@emotion/styled";

export const StyledTextArea = Styled.textarea`
  width: 100%;
  height: 100%;
  border: 1px solid black;
  background-color: transparent;
  overflow: hidden;
  resize: none;

  &::placeholder {
    color: ${(props) => props?.style?.color}
  }
`;
