import { lighten, darken } from "polished";
import {
  colors as colorVariables,
  colorVarPercentage,
} from "../variables/colors";

export const colors = {
  common: {
    black: colorVariables.black,
    white: colorVariables.white,
  },
  primary: {
    main: colorVariables.primary,
    light: lighten(colorVarPercentage, colorVariables.primary),
    dark: darken(colorVarPercentage, colorVariables.primary),
    contrastText: colorVariables.white,
  },
  secondary: {
    main: colorVariables.secondary,
    light: lighten(colorVarPercentage, colorVariables.secondary),
    dark: darken(colorVarPercentage, colorVariables.secondary),
    contrastText: colorVariables.white,
  },
  error: {
    main: colorVariables.error,
    light: lighten(colorVarPercentage, colorVariables.error),
    dark: darken(colorVarPercentage, colorVariables.error),
    contrastText: colorVariables.white,
  },
  warning: {
    main: colorVariables.warning,
    light: lighten(colorVarPercentage, colorVariables.warning),
    dark: darken(colorVarPercentage, colorVariables.warning),
    contrastText: colorVariables.white,
  },
  info: {
    main: colorVariables.info,
    light: lighten(colorVarPercentage, colorVariables.info),
    dark: darken(colorVarPercentage, colorVariables.info),
    contrastText: colorVariables.white,
  },
  success: {
    main: colorVariables.success,
    light: lighten(colorVarPercentage, colorVariables.success),
    dark: darken(colorVarPercentage, colorVariables.success),
    contrastText: colorVariables.white,
  },
};

export default colors;
