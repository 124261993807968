import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

import Label from "../Label/Label";
import RadioGroup from "../FormGroup/RadioGroup";

interface RadioTextButtonProps {
  id: string;
  title: string;
  options: any[];
  callback: (id: string, value: string) => void;
}

const RadioTextButton = ({
  id,
  title,
  options,
  callback,
}: RadioTextButtonProps) => {
  const radioChange = (event: any, value: string) => {
    callback(id, value);
  };
  return (
    <FormControl>
      {title && <Label id="radio-text-label">{title}:</Label>}
      <RadioGroup
        aria-labelledby="radio-text-label"
        name="radio-buttons-group"
        onChange={radioChange}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.optionItemId}
            value={option.value}
            control={<Radio />}
            label={option.name}
            disabled={option.disabled}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioTextButton;
