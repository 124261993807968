export const colors = {
  primary: "#1976d2",
  secondary: "#9c27b0",
  error: "#d32f2f",
  warning: "#ed6c02",
  info: "#0288d1",
  success: "#2e7d32",
  black: "#000",
  white: "#FFF",
};

//control the range of lighten and darken variation
export const colorVarPercentage = 0.3;
