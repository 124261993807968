import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  memo,
  useContext,
} from "react";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import axios from "axios";
import FormGroup from "../FormGroup/FormGroup";
import Label from "../Label/Label";
import Editor from "../Editor/Editor";
import SingleMenu from "../GraphicEditor/SingleMenu";
import { Container, PreviewContainer2 } from "./styles";
import reactImageSize from "react-image-size";
import { ErrorContext } from "../../pages/Editor";
import SaveMessage from "../SaveMessage/SaveMessage";
import ImageService from "../../services/ImageService";

interface UploadImageProps {
  callback: (id: string, mime: string, file: string, title: string) => void;
  graphicMode?: boolean;
  height: number;
  id: string;
  title: string;
  width: number;
  onChange?: (id: string, item: any) => void;
  initialImage?: string;
  marketplaceId?: string;
  documentId?: string;
}

const UploadImage = ({
  callback,
  graphicMode,
  height,
  id,
  onChange,
  title,
  width,
  initialImage,
  marketplaceId,
  documentId,
}: UploadImageProps) => {
  const [isDragActive, setIsDragActive] = useState(false);
  const dropZoneRef = useRef<null | HTMLDivElement>(null);
  const [fileData, setFileData] = useState<File>();
  const [previewImage, setPreviewImage] = useState<string>(initialImage || "");
  const [showEditor, setShowEditor] = useState(false);
  const [inputState, setinputState] = useState<"primary" | "error">("primary");
  const context = useContext(ErrorContext);
  const [saveState, setSaveState] = useState<{
    show: boolean;
    error: boolean;
    message: undefined | string;
  }>({
    show: false,
    error: true,
    message:
      "Unable to load remote image. If you would like to use a different image, please upload it or supply the URL where the image can befound.",
  });

  const fetchImage = useCallback(async () => {
    const url = "https://jcdevapi.cierant.com/userasset?";

    const image = await axios
      .get(url, {
        params: {
          marketplaceId: marketplaceId,
          docid: documentId,
          filename: initialImage,
        },
        headers: {
          Authorization: process.env.REACT_APP_API_KEY || "",
        },
      })
      .then((res) => res.data.image)
      .catch((err) => {
        console.log("err: ", err);
      });

    setPreviewImage(`data:image/png;base64,${image.data}`);

    callback(id, image.mime, image.data, title);

    if (onChange) {
      onChange(id, {
        type: image.mime,
        file: image.data,
        img: previewImage,
      });
    }
  }, [callback, context, height, id, onChange, previewImage, width]);

  useEffect(() => {
    setPreviewImage(initialImage || "");

    if (initialImage !== "") {
      fetchImage();
    }
  }, [initialImage]);

  const handleDragIn = useCallback((event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      setIsDragActive(true);
    }
  }, []);

  const handleDragOut = useCallback((event: any) => {
    event.preventDefault();
    event.stopPropagation();

    setIsDragActive(false);
  }, []);

  const handleDrag = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();

      if (!isDragActive) {
        setIsDragActive(true);
      }
    },
    [isDragActive]
  );

  const handleDrop = useCallback(
    async (event: any) => {
      event.preventDefault();
      event.stopPropagation();

      setIsDragActive(false);

      const image = event.dataTransfer.files.item(0);

      const imageurl = await ImageService.getFileToBase64(image).then(
        (result: any) => result
      );

      const imageSize = await reactImageSize(imageurl)
        .then((props: any) => props)
        .catch((errorMessage: any) => console.log(errorMessage));

      const desiredDIP = 300;
      if (
        imageSize.width / width < width * desiredDIP ||
        imageSize.height / height < height * desiredDIP
      ) {
        setinputState("error");
        context?.setErrorState({
          show: true,
          message:
            "The image uploaded do not have the size required. Please upload a bigger image.",
        });
        return;
      }

      if (image) {
        setFileData(image);
        event.dataTransfer.clearData();
      }
    },
    [context, height, width]
  );

  const handleUpload = useCallback(
    async (event: any) => {
      setinputState("primary");
      const imageurl = await ImageService.getFileToBase64(
        event.target.files[0]
      ).then((result: any) => result);

      const imageSize = await reactImageSize(imageurl)
        .then((props: any) => props)
        .catch((errorMessage: any) => console.log(errorMessage));

      const desiredDIP = 300;
      if (
        imageSize.width < width * desiredDIP ||
        imageSize.height < height * desiredDIP
      ) {
        setinputState("error");
        context?.setErrorState({
          show: true,
          message:
            "The image uploaded do not have the size required. Please upload a bigger image.",
        });
        return;
      }
      setFileData(event.target.files[0]);
      if (
        event.target.files[0] !== null &&
        event.target.files[0] !== undefined
      ) {
        ImageService.getFileToBase64(event.target.files[0]).then(
          (result: any) => {
            setPreviewImage(result);

            const fileString = result?.toString();
            const file = fileString?.split(",");

            callback(id, event.target.files[0].type, file[1], title);

            if (onChange) {
              onChange(id, {
                type: event.target.files[0].type,
                file: file[1],
                img: previewImage,
              });
            }
          }
        );
      }
    },
    [callback, context, height, id, onChange, previewImage, width]
  );

  useEffect(() => {
    const tempZoneRef = dropZoneRef?.current;
    if (tempZoneRef) {
      tempZoneRef.addEventListener("dragenter", handleDragIn);
      tempZoneRef.addEventListener("dragleave", handleDragOut);
      tempZoneRef.addEventListener("dragover", handleDrag);
      tempZoneRef.addEventListener("drop", handleDrop);
    }

    return () => {
      tempZoneRef?.removeEventListener("dragenter", handleDragIn);
      tempZoneRef?.removeEventListener("dragleave", handleDragOut);
      tempZoneRef?.removeEventListener("dragover", handleDrag);
      tempZoneRef?.removeEventListener("drop", handleDrop);
    };
  }, [previewImage, handleDragIn, handleDragOut, handleDrag, handleDrop]);

  useEffect(() => {
    if (fileData !== null && fileData !== undefined) {
      ImageService.getFileToBase64(fileData).then((result: any) => {
        setPreviewImage(result);

        const fileString = result?.toString();
        const file = fileString?.split(",");

        callback(id, fileData.type, file[1], title);

        if (onChange) {
          onChange(id, {
            type: fileData.type,
            file: file[1],
            img: previewImage,
          });
        }
      });
    }
  }, [fileData, id, callback, onChange, previewImage]);

  const changeImage = () => {
    setFileData(undefined);
    setPreviewImage("");
  };

  const processEditedImage = (e: any) => {
    setShowEditor(false);
    setFileData(e.dest);

    ImageService.getFileToBase64(e.dest).then((result: any) => {
      setPreviewImage(result);

      const fileString = result?.toString();
      const file = fileString?.split(",");

      callback(id, e.dest.type, file[1], title);
    });
  };

  const closeEditor = () => {
    setShowEditor(false);
  };

  const minSize = `(min required size: ${width * 300} x ${height * 300})`;
  const minSizeGraphic = `(min size: ${width * 300} x ${height * 300})`;

  const editMenu = (photo?: React.ReactNode) => {
    return (
      <SingleMenu
        title={photo}
        options={[
          {
            icon: <CloudUploadOutlinedIcon fontSize="small" />,
            name: "Change Photo",
            action: changeImage,
          },
          {
            icon: <EditOutlinedIcon fontSize="small" />,
            name: "Edit Photo",
            action: () => setShowEditor(true),
          },
        ]}
      />
    );
  };

  return (
    <FormGroup style={{ width: "100%", height: "100%" }}>
      {!graphicMode && (
        <Label id="select-label" color={inputState}>
          {title} {minSize}
        </Label>
      )}
      {previewImage === "" && (
        <Container
          ref={dropZoneRef}
          className={graphicMode ? "adjustable" : ""}
        >
          {!graphicMode ? (
            <>
              <CloudUploadOutlinedIcon className="upload-icon" />
              <div className="upload-text">
                Drag file here
                <br />
                or{" "}
                <label className={"upload-btn"} htmlFor={`upload-button-${id}`}>
                  Browse
                </label>{" "}
              </div>
            </>
          ) : (
            <>
              <CloudUploadOutlinedIcon className="upload-icon small" />
              <label className={"upload-btn"} htmlFor={`upload-button-${id}`}>
                Drag file here or Browse
              </label>
              <div className="size">{minSizeGraphic}</div>
            </>
          )}
          <input
            type="file"
            id={`upload-button-${id}`}
            style={{ display: "none" }}
            onChange={handleUpload}
            accept=".png, .jpg, .jpeg"
          />
        </Container>
      )}

      {previewImage !== "" && (
        <PreviewContainer2
          className={graphicMode ? "graphic-mode" : "form-mode"}
        >
          {editMenu(
            <img className="upload-image" src={previewImage} alt="Preview" />
          )}
        </PreviewContainer2>
      )}
      {showEditor && (
        <Editor
          image={previewImage}
          onProcess={processEditedImage}
          onClose={closeEditor}
        />
      )}
      <SaveMessage
        show={saveState.show}
        error={saveState.error}
        message={saveState.message}
        close={() =>
          setSaveState({
            show: false,
            error: false,
            message: undefined,
          })
        }
      />
    </FormGroup>
  );
};

export default memo(UploadImage);
