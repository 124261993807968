import { breakpoints } from "./breakpoints";
import { colors } from "./colors";
import spacing from "./spacing";
import { typography } from "./typography";
import shape from "./shape";

const variables = {
  breakpoints,
  colors,
  spacing,
  typography,
  shape,
};

export default variables;
