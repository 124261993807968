import React from "react";
import { StyledButton } from "./styles";

export type props = {
  id?: string;
  children: React.ReactNode;
  color?: "primary" | "secondary" | "success" | "error";
  variant?: "text" | "contained" | "outlined";
  disabled?: boolean;
  onClick?: (props: any) => any;
  className?: string;
  onMouseOver?: (props: any) => any;
  onMouseLeave?: (props: any) => any;
};

const Button = ({
  id,
  children,
  color,
  variant,
  disabled,
  onClick,
  className,
  onMouseOver,
  onMouseLeave,
}: props) => {
  return (
    <StyledButton
      variant={variant}
      color={color}
      disabled={disabled}
      onClick={onClick}
      className={className}
      id={id}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
