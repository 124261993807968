import { styled } from "@mui/material/styles";

export const StyledContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(3),
  [`@media (min-width:${theme.breakpoints.values.md}px)`]: {
    overflowY: "auto",
    height: "calc(100vh - 20px)",
  },
  "& .prof-container": {
    display: "flex",
    marginBottom: theme.spacing(4),
  },
  "& .button-container": {
    minWidth: "150px",
  },
  "& .css-1oxypcb-MuiFormGroup-root": {
    marginBottom: theme.spacing(0),
  },
  "& .divider": {
    marginBottom: theme.spacing(4),
  },
  "& .save-container": {
    display: "flex",
    justifyContent: "flex-end",
  },
}));
