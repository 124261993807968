import {
  typography as typographyVariables,
  fontBreakpoint,
} from "../variables/typography";
import { breakpoints } from "../variables/breakpoints";

const { h1, h2, h3, h4, h5, h6, p, fontFamily, global } = typographyVariables;
const mediaQuery = `@media (min-width:${breakpoints[fontBreakpoint]}px)`;

export const typography = {
  fontFamily: fontFamily,
  fontSize: global,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontFamily: h1.fontFamily,
    fontSize: h1.xs.fontSize,
    lineHeight: h1.xs.fontSize,
    fontWeight: 300,
    [mediaQuery]: {
      fontSize: h1[fontBreakpoint].fontSize,
      lineHeight: h1[fontBreakpoint].fontSize,
    },
  },
  h2: {
    fontFamily: h2.fontFamily,
    fontSize: h2.xs.fontSize,
    lineHeight: h2.xs.fontSize,
    fontWeight: 300,
    [mediaQuery]: {
      fontSize: h2[fontBreakpoint].fontSize,
      lineHeight: h2[fontBreakpoint].fontSize,
    },
  },
  h3: {
    fontFamily: h3.fontFamily,
    fontSize: h3.xs.fontSize,
    lineHeight: h3.xs.fontSize,
    fontWeight: 300,
    [mediaQuery]: {
      fontSize: h3[fontBreakpoint].fontSize,
      lineHeight: h3[fontBreakpoint].fontSize,
    },
  },
  h4: {
    fontFamily: h4.fontFamily,
    fontSize: h4.xs.fontSize,
    lineHeight: h4.xs.fontSize,
    fontWeight: 300,
    [mediaQuery]: {
      fontSize: h4[fontBreakpoint].fontSize,
      lineHeight: h4[fontBreakpoint].fontSize,
    },
  },
  h5: {
    fontFamily: h5.fontFamily,
    fontSize: h5.xs.fontSize,
    lineHeight: h5.xs.fontSize,
    fontWeight: 300,
    [mediaQuery]: {
      fontSize: h5[fontBreakpoint].fontSize,
      lineHeight: h5[fontBreakpoint].fontSize,
    },
  },
  h6: {
    fontFamily: h6.fontFamily,
    fontSize: h6.xs.fontSize,
    lineHeight: h6.xs.fontSize,
    fontWeight: 300,
    [mediaQuery]: {
      fontSize: h6[fontBreakpoint].fontSize,
      lineHeight: h6[fontBreakpoint].fontSize,
    },
  },
  subtitle1: {
    fontFamily: p.fontFamily,
    fontSize: "1rem",
    lineHeight: 1.75,
    fontWeight: 400,
  },
  subtitle2: {
    fontFamily: p.fontFamily,
    fontSize: "0.875rem",
    lineHeight: 1.57,
    fontWeight: 500,
  },
  body1: {
    fontFamily: p.fontFamily,
    fontSize: p.xs.fontSize,
    lineHeight: p.xs.fontSize,
    fontWeight: 300,
    [mediaQuery]: {
      fontSize: p[fontBreakpoint].fontSize,
      lineHeight: p[fontBreakpoint].fontSize,
    },
  },
  body2: {
    fontFamily: p.fontFamily,
    fontSize: "0.875rem",
    lineHeight: 1.43,
    fontWeight: 400,
    [mediaQuery]: {
      fontSize: p[fontBreakpoint].fontSize,
      lineHeight: p[fontBreakpoint].fontSize,
    },
  },
};

export default typography;
