import { styled } from "@mui/material/styles";

export const StyledContainer = styled("div")(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.palette.grey[100],
  display: "flex",
  height: "100%",
  justifyContent: "center",
  left: 0,
  padding: theme.spacing(3),
  position: "absolute",
  top: 0,
  width: "100%",
  "&.opacity": {
    opacity: 0.7,
  },
}));
