import { styled } from "@mui/material/styles";
import EmotionStyled from "@emotion/styled";
import Theme from "../../base/theme/Theme";

export const Container = EmotionStyled.div`
  width: 175px;
  height: 175px;
  background-color: lightgray;
  border: 1px dashed black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;

  &.adjustable{
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .upload-btn{
    margin-left: 5px;
    margin-right: 5px;
    color: ${Theme.palette.primary.main};
    font-weight: bold;
    cursor: pointer;
  }

  &.preview-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    max-width: 100%;
  }
  .upload-image {
    max-width: 175px;
    max-height: 175px;
  }
  .change-btn {
    width: 100px;
  }

  .upload-icon {
    font-size: 80px;

    &.small{
      font-size: 40px;
    }
  }

  .upload-text{
    line-height: ${Theme.typography.pxToRem(20)};
  }

  .size{
    font-size: ${Theme.typography.pxToRem(12)};
  }
`;

export const PreviewContainer2 = EmotionStyled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  max-width: 100%;
  position: relative;

  &.graphic-mode{
    width: 100%;
    height: 100%;
    text-align: center;

    .upload-image{
      height: 100%;
    }

    .button-container {
      align-items: center;
      flex-direction: column;
      height: 100%;
      justify-content: space-evenly;
      position: absolute;
      width: 100%;
    }

    .menu-container{
      align-items: center;
      background-color: ${Theme.palette.primary.main};
      color: ${Theme.palette.primary.contrastText};
      bottom: 0;
      display: flex;
      height:  ${Theme.spacing(4)};
      justify-content: center;
      position: absolute;
      right: -32px;
      width: ${Theme.spacing(4)};
    }
  }

  .upload-image {
    max-width: 175px;
    max-height: 175px;
    pointer-events: none;
  }
  .change-btn {
    width: 100px;
  }

  .image-button{
    align-items: center;
    border: 0;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%;
  }

  .menu {
    align-items: center;
    background-color: ${Theme.palette.primary.main};
    border-radius: 0;
    border: none;
    bottom: 0;
    color: ${Theme.palette.common.white};
    display:flex;
    height: 100%;
    justify-content: center;
    margin-bottom: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: 0;
    width: 100%;

    &:hover{
      background-color: ${Theme.palette.primary.main};
    }

    &:hover{
      img{
        opacity: 0.5;
      }
    }
  }

  &.form-mode{
    .menu {
      position: relative;
      height: auto;
      width: auto;
    }
  }
`;

export const PreviewContainer = EmotionStyled.div`
  display: flex;
  position: relative;

  &.graphic-mode{
    width: 100%;
    height: 100%;
    text-align: center;

    .upload-image{
      height: 100%;
    }

    .button-container {
      align-items: center;
      flex-direction: column;
      height: 100%;
      justify-content: space-evenly;
      position: absolute;
      width: 100%;
    }

    button:first-of-type {
      margin-bottom: ${Theme.spacing(2)};
    }

    .menu-container{
      align-items: center;
      background-color: ${Theme.palette.primary.main};
      color: ${Theme.palette.primary.contrastText};
      bottom: 0;
      display: flex;
      height:  ${Theme.spacing(4)};
      justify-content: center;
      position: absolute;
      right: -32px;
      width: ${Theme.spacing(4)};
    }
  }

  .upload-image {
    max-width: 175px;
    max-height: 175px;
    pointer-events: none;
  }
  .change-btn {
    width: 100px;
  }

  .image-button{
    align-items: center;
    border: 0;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%;
  }

  .image-wrapper{
    position: relative;
  }
`;

export const MenuContainer = EmotionStyled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  right: -52px;
  top: 0px;

  button{
    min-width: 48px;

    &:first-of-type {
      margin-bottom: ${Theme.spacing(2)};
    }
  }
`;

export const StyledButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  "& > button:first-of-type": {
    marginRight: theme.spacing(3),
  },
}));
